






































































































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import apiSvc from "@/services/api";

import { parseISO, differenceInMinutes } from "date-fns";

import axios from "axios";
import { AxiosError } from "axios";

import {
    SessionComment,
    SessionDataObject,
    AnalyticsLivestreamVisitor,
    AnalyticsLivestream,
    ContEdQuestion,
    AnalyticsCeQuestionUser
} from "@/types/interfaces";

interface ContEdQuestionFlat {
    name: string;
    id: string;
    range: string;
}

export default Vue.extend({
    components: {
        AnalyticsDateControl,
        Spinners
    },
    data() {
        return {
            comments: [] as Array<SessionComment>,
            commentsLoading: false,
            session: null as SessionDataObject | null,
            sessionLoading: false
        };
    },
    computed: {
        loading(): boolean {
            return analyticsStore.loading;
        },

        sessionId(): string {
            return this.$route.params.sessionId
                ? this.$route.params.sessionId
                : "";
        },

        itemData(): AnalyticsLivestream {
            return analyticsStore.singleLivestream;
        },

        users(): Array<AnalyticsLivestreamVisitor> {
            return this.itemData.users;
        },

        sessionName(): string {
            return this.itemData.sessionName;
        },

        tableHeaders(): Array<string> {
            const headers = [
                "Attendee Id",
                "First Name",
                "Last Name",
                "Company Name",
                "Email",
                "Time Watched"
            ];

            if (this.session) {
                headers.push("% of Time Watched");
            }

            if (this.ceQuestions.length) {
                this.ceQuestions.forEach((question: ContEdQuestionFlat) => {
                    headers.push(
                        `CE Question #${question.id} - ${question.name} (${question.range})`
                    );
                });

                headers.push("CE Questions Answered");
                headers.push("% of CE Questions Answered");
            }

            return headers;
        },

        totalCols(): number {
            return this.tableHeaders.length;
        },

        flatData(): Array<Array<string>> {
            const rows = [] as Array<Array<string>>;

            this.users.forEach((user) => {
                const userMinutes = user.minutes ? user.minutes : 0;

                const row = [
                    user.uid,
                    user.firstName,
                    user.lastName,
                    user.companyName,
                    user.email,
                    `${userMinutes}`
                ];

                if (this.session) {
                    const percentOfSession = (
                        (user.minutes / this.sessionDuration) *
                        100
                    ).toFixed(2);
                    row.push(`${percentOfSession}%`);
                }

                if (this.ceQuestions.length) {
                    const totalQuestions = this.ceQuestions.length;
                    let questionsComplete = 0;

                    this.ceQuestions.forEach((question: ContEdQuestionFlat) => {
                        const thisUserAnswers = this.ceQuestionData.find(
                            (record) => record.uid === user.uid
                        );

                        if (!thisUserAnswers) {
                            row.push("NOT PRESENT");
                        } else {
                            const thisQuestionAnswers = thisUserAnswers.entries.filter(
                                (entry) => entry.questionId === question.id
                            );

                            let questionToUse;

                            if (thisQuestionAnswers.length > 1) {
                                /**
                                 * Somehow they answered a question more than once. Take the first non-incomplete answer. If there
                                 * isn't one, take the first answer.
                                 */
                                const completeQuestion = thisQuestionAnswers.find(
                                    (thisQuestion) =>
                                        !thisQuestion.questionIncomplete
                                );
                                questionToUse = completeQuestion
                                    ? completeQuestion
                                    : thisQuestionAnswers[0];
                            } else if (thisQuestionAnswers.length === 1) {
                                questionToUse = thisQuestionAnswers[0];
                            }

                            if (questionToUse) {
                                if (questionToUse.questionIncomplete) {
                                    row.push("INCOMPLETE");
                                } else {
                                    questionsComplete++;
                                    const answer = JSON.parse(
                                        questionToUse.answers
                                    );
                                    const answerText = Object.values(
                                        answer
                                    )[0] as string;
                                    row.push(answerText);
                                }
                            } else {
                                row.push("NOT PRESENT");
                            }
                        }
                    });

                    row.push(`${questionsComplete}`);
                    const percentOfQuestions = (
                        (questionsComplete / totalQuestions) *
                        100
                    ).toFixed(2);
                    row.push(`${percentOfQuestions}%`);
                }

                rows.push(row);
            });

            return rows;
        },

        commentsHeaders(): Array<string> {
            return ["Attendee ID", "Attendee Name", "Time", "Comment"];
        },

        commentsFlatData(): Array<Array<string>> {
            return this.comments.map((item) => [
                item.attendeeId ? `${item.attendeeId}` : "",
                item.attendeeName ? `${item.attendeeName}` : "",
                item.createdTime ? `${item.createdTime}` : "",
                item.text ? `${item.text}` : ""
            ]);
        },

        tableFooter(): Array<string> {
            const extraBlanks = [];

            for (let i = 0; i < this.totalCols - 4; i++) {
                extraBlanks.push("");
            }

            return [
                "Total Watchers",
                `${this.totalVisitors}`,
                "Total Minutes Watched",
                `${this.totalMinutes}`,
                ...extraBlanks
            ];
        },

        totalMinutes(): number {
            return this.users.reduce((acc, curr) => {
                return (acc += curr.minutes);
            }, 0);
        },

        csvUrl(): string {
            return this.getCsvUrl();
        },

        commentsCsvUrl(): string {
            return this.getCommentsCsvUrl();
        },

        totalVisitors(): number {
            return this.users.length;
        },

        csvFilename(): string {
            return `session-${this.sessionSlugForCsv}.csv`;
        },

        commentsCsvFileName(): string {
            return `comments-${this.sessionSlugForCsv}.csv`;
        },

        sessionSlugForCsv(): string {
            return this.sessionName
                ? Vue.prototype.MgSimpleSlugify(this.sessionName)
                : this.sessionId;
        },

        ceQuestionData(): Array<AnalyticsCeQuestionUser> {
            return analyticsStore.ceQuestionData;
        },

        ceQuestions(): Array<ContEdQuestionFlat> {
            return this.session && this.session.ceQuestions
                ? this.session.ceQuestions.map((question: ContEdQuestion) => {
                      const start = question.minTimeAfterStart;
                      const variance = question.variance;
                      const range =
                          variance && variance > 0
                              ? `${start}-${start +
                                    variance} minutes after start`
                              : `${start} minutes after start`;

                      return {
                          id: question.id,
                          name: question.question.title
                              ? question.question.title
                              : "",
                          range: range
                      };
                  })
                : [];
        },

        sessionDuration(): number {
            const data = this.session;

            if (!data || !data.startTime || !data.endTime) {
                return 0;
            }

            const start = parseISO(data.startTime);
            const end = parseISO(data.endTime);

            return differenceInMinutes(end, start);
        }
    },

    mounted() {
        this.getAllData();
    },

    methods: {
        async getData(): Promise<void> {
            analyticsStore.getSingleLivestream(this.sessionId);
        },

        /**
         * Separate get data for this one since we're not making the comments dependent on
         * the date control.
         */
        async getAllData(): Promise<void> {
            this.getComments();
            this.sessionLoading = true;
            // Gets the session name and users/userdata.
            await this.getData();

            try {
                // Gets the actual session object. We need it for CE Questions, otherwise we won't know what the questions are.
                await this.getSession();
                // Get the CE question answers for each user.
                await this.getCeQuestions();
            } catch (err) {
                const error = err as AxiosError;
                if (error.response?.status === 404) {
                    console.log("session not found");
                } else {
                    console.error(error);
                }
            }
            this.sessionLoading = false;
        },

        async getSession(): Promise<void> {
            const response = await apiSvc.getSession(this.sessionId);

            this.session = response.data;
        },

        getComments(): void {
            this.commentsLoading = true;
            apiSvc
                .getVideoComments(this.sessionId, "")
                .then((res) => {
                    this.comments = res.data;
                })
                .finally(() => {
                    this.commentsLoading = false;
                });
        },

        getCeQuestions(): void {
            analyticsStore.getCeQuestionData(this.sessionId);
        },

        getCommentsCsvUrl(): string {
            const dataArray = [this.commentsHeaders, ...this.commentsFlatData];

            return transformDataToCsvUrl(dataArray);
        },

        getCsvUrl(): string {
            const dataArray = [
                this.tableHeaders,
                ...this.flatData,
                this.tableFooter
            ];

            return transformDataToCsvUrl(dataArray);
        }
    }
});
