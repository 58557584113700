





















































































import Vue  from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsVideoStreamItem } from "@/types/interfaces";

export default Vue.extend ({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    computed: {
        
        loading(): boolean {
            return analyticsStore.loading;
        },
    
        videoData(): Array<AnalyticsVideoStreamItem> {
            return analyticsStore.videoStreams;
        },
    
        sortedData(): Array<AnalyticsVideoStreamItem> {
            return this.sortedVideoData();
        },
    
        tableHeaders(): Array<string> {
            return [
                "Session ID",
                "Session Name",
                "Start Time ISO",
                "End Time ISO",
                "Total Minutes",
                "Unique Visitors"
            ];
        },
    
        tableFooter(): Array<string> {
            return [
                "Total",
                "",
                "",
                "",
                `${this.totalHits}`,
                `${this.totalUniques}`
            ];
        },
    
        totalHits(): number {
            return this.videoData.reduce((acc, curr) => {
                return (acc += curr.totalMinutes);
            }, 0);
        },
    
        totalUniques(): number {
            return this.videoData.reduce((acc, curr) => {
                return (acc += curr.participants);
            }, 0);
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        }
    },
    methods: {

        sortedVideoData() {
            return this.videoData.sort((a, b) => b.totalMinutes - a.totalMinutes);
        },

        getData() {
            analyticsStore.getVideoStreams();
        },
    
        getCsvUrl() {
            const valuesOnly = this.videoData.map((item) => [
                item.sessionId,
                item.title,
                item.startTimeISO,
                item.endTimeISO,
                item.totalMinutes,
                item.participants
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        }
    },
    mounted() {
        this.getData();
    }

})
